import React, { Fragment, useRef, useState, useEffect } from 'react';
import { useAuth, } from '../contexts/AuthContext'
import '../App.css';
import { useParams } from "react-router-dom"
import ProductModal from '../components/Modals/ProductModal';
import { ShopOutlined, StarOutlined, StarFilled, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import Nav from '../components/Nav/Nav';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import google from '../google.PNG';
import instagram from '../insta.png';
import tiktok from '../tiktok-logo.png'
import vegi from '../vegi.png'
import halal from '../halal.png'


function Menu() {
  const { getRestaurant, currentRestaurant, listProducts,
    getProducts,
    getCategories,
    listCategories, language , isProducts } = useAuth()

  const { id } = useParams();
  const refs = useRef([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  const refList = useRef([]);
  const [currentList, setCurrentList] = useState()
  const [currentMenu, setCurrentMenu] = useState(-1)
  const MyCategories = listCategories?.filter(function (categorie) { return categorie.restaurant === id && categorie.status === "visible" });
  const MyProducts = listProducts?.filter(function (product) { return product.restaurant === id && product.status === "visible" });
  const [selectedProduct, setSelectedProduct] = useState('')

  const trans = [
    {
      en: "Leave a review",
      es: "Deja un comentario",
      it: "Lascia un commento",
      pt: "Deixar um comentário",
      de: "Eine Meinung hinterlassen",
      nl: "Laat een reactie achter",
      zh: "发表评论",
      ar: "اترك تعليقاً"
    },
    {
      en: "tell us about your experience",
      es: "cuéntenos su experiencia",
      it: "raccontateci la vostra esperienza",
      pt: "fale-nos da sua experiência",
      de: "berichten Sie uns von Ihren Erfahrungen",
      nl: "vertel ons over je ervaring",
      zh: "告诉我们您的经历",
      ar: "أخبرنا عن تجربتك"
    },
    {
      ar: "مسببات الحساسية",
      de: "Allergene",
      en: "Allergens",
      es: "Alérgenos",
      it: "Allergeni",
      nl: "Allergenen",
      pt: "Alergénios",
      zh: "过敏原"
    },
    {
      en: "Options",
      es: "Opciones",
      it: "Opzioni",
      pt: "Opções",
      de: "Optionen",
      nl: "Opties",
      zh: "选项",
      ar: "الخيارات"
    },
  ]

  useEffect(() => {
    getRestaurant(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (currentRestaurant) {
      getCategories(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRestaurant]);

  useEffect(() => {
    if (listCategories) {
      getProducts(id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCategories]);


  useEffect(() => {
  setTimeout(function(){
   setIsTimeout(true)
}, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  useEffect(() => {
    isTimeout && !isProducts && window.location.reload()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTimeout, isProducts ]);

  

  useEffect(() => {
    selectedProduct && setIsOpen(true)
  }, [selectedProduct]);


  const onPressStart = (index) => {
    refs.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    })
  };


  const listFn = (index) => {
    setCurrentList(index)
    setTimeout(onPressStart, 1000, index)
    refList.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  };

  const dots = (text, number) => {
    const basic = "No translation available"
    if (!text) {
      return basic
    }

    const words = text.split(' ');
    if (words.length > number) {
      const truncatedText = words.slice(0, number).join(" ");
      let newText = `${truncatedText}...`
      return newText
    } else {
      return text
    }
  };


  const bgC = (index) => {
    let color
    if (currentMenu === index) {
      color = '3px solid'
    }
    else if (currentList === index && currentMenu === index) {
      color = '3px solid'
    } else {
      return
    }

    return color
  }



  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);



  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    document.body.style.overflow = 'unset';
    refList.current.forEach((element, index) => {
      let position
      position = element.getBoundingClientRect()
      if (position.top > -(position.height / 2) && position.top < 0.3) {

        setCurrentMenu(index)
        setTimeout(onPressStart, 1000, index)
      }

    });
  }, [scrollTop]);



  return (
    <div className='menu-wrapper'>
      <div className='menu-container2'>
        <Nav menu></Nav>

        <div className='menu-filter'>
          <LazyLoadImage fetchpriority="low" rel="preload" className='img-container' src={listProducts && currentRestaurant?.photo} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />

        </div>

        {listProducts ? <> <div className='menu-title' >
          <div className='menu-title-container'>
            <p className='menu-title-text'>{currentRestaurant?.name}  </p>

            <div className='menu-links' >



              {currentRestaurant.social?.google.length > 0 && <a className='link-social-container' href={currentRestaurant.social.google} target="_blank" rel='noreferrer' >
                <img className='link-social' src={google} alt="Logo" />
              </a>}

              {currentRestaurant.social?.instagram.length > 0 && <a className='link-social-container' href={currentRestaurant.social.instagram} target="_blank" rel='noreferrer' >
                <img className='link-social tiktok' src={instagram} alt="Logo" />
              </a>}

              {currentRestaurant.social?.tiktok.length > 0 && <a className='link-social-container' href={currentRestaurant.social.tiktok} target="_blank" rel='noreferrer' >
                <img className='link-social insta' src={tiktok} alt="Logo" />
              </a>}


            </div>
          </div>
        </div>
          <div className='sub-menu-container'>
            <div className='sub-menu-title' ><ShopOutlined /> {language ? currentRestaurant.translated[language] : currentRestaurant?.type}</div>
            <button className="button save more-option menu-review-header" type="button">
              <StarFilled></StarFilled> {currentRestaurant?.review && currentRestaurant?.review === "demo" ?
              <span style={{ color: 'inherit' }} onClick={() => alert("Indisponible en mode démo")}>
 {language ? trans[0][language] : "Laisser un avis"}
              </span> : 
               <a href={currentRestaurant?.review} target="_blank" rel='noreferrer' style={{ color: 'inherit' }}>{language ? trans[0][language] : "Laisser un avis"}
              </a>}
            </button>
          </div>

        </> :
          <div className='loading-menu'>
            <LoadingOutlined spin />
          </div>}




       {listProducts && <div className='nav'>
          {MyCategories?.map((category, index) => (

            <div key={index} ref={(element) => {
              refs.current[index] = element;
            }} onClick={() => listFn(index)}
              className='menu-container' style={{ borderBottom: bgC(index) }}
            >
              <div className='menu-text'>
                {language ? category.translated[language] : category.name}
              </div>

            </div>

          ))}
        </div>}

        {isOpen && <ProductModal name={selectedProduct.name}
          price={selectedProduct.price} photo={selectedProduct.picture} translatedOpt={selectedProduct.translatedOpt} description={language && selectedProduct.translated[language] ? selectedProduct.translated[language] : selectedProduct.description} setIsOpen={setIsOpen}
          allergens={language && selectedProduct?.allergens && selectedProduct?.translated2[language] ? selectedProduct?.translated2[language] : selectedProduct.allergens} label={selectedProduct?.label} />}
  { listProducts &&     <div className='product-wrapper'>
          {MyCategories?.map((category, index) => (

            <div key={index} ref={(element) => {
              refList.current[index] = element;
            }}
              className='product-container'
            >
              <div className='category-title'>
                {language ? category.translated[language] : category.name}
              </div>

              {MyProducts?.map((product, index) => {
                return (
                  <Fragment key={index}>

                    {category.id === product.category && <div className='product' onClick={() => { setIsOpen(true); setSelectedProduct(product) }} >
                      <div className={`product-text-container  ${!product.picture && 'noMWidth'}`}>
                        <p>{product.name} {product?.label && <span><img className='label-title' src={product.label === "halal" ? halal : vegi} alt='logo' /></span>}</p>
                        {product.description !== product.name && <p className='product-desc-text'>{dots((language ? product?.translated[language] : product.description), (!product.picture ? 14 : 10))}</p>}
                        {product.description === product.name && language && <p className='product-desc-text'>{dots((language ? product?.translated[language] : product.description), (!product.picture ? 14 : 10))}</p>}

                        {product?.translatedOpt?.length === 0 && <p className='product-price-text'>{parseFloat(product.price).toFixed(2)} €    {product?.allergens && <span className='allergen'>{language ? trans[2][language] : 'Allergènes'}</span>}</p>}
                        {product?.translatedOpt?.length > 0 && product?.translatedOpt.length && product.translatedOpt[0].type === '2' && <p className='product-price-text'>{parseFloat(product.price).toFixed(2)} € <RightOutlined className='more-option-price' />    {product?.allergens && <span className='allergen'>{language ? trans[2][language] : 'Allergènes'}</span>}</p>}
                        {product?.translatedOpt?.length > 0 && product.translatedOpt[0].type === '1' && <p className='product-price-text '>{language ? trans[3][language] : "Options"} <RightOutlined className='more-option-price' />  {product?.allergens && <span className='allergen'>{language ? trans[2][language] : 'Allergènes'}</span>}</p>}
                        {!product?.translatedOpt && <p className='product-price-text'>{parseFloat(product.price).toFixed(2)} €    {product?.allergens && <span className='allergen'>{language ? trans[2][language] : 'Allergènes'}</span>}</p>}

                      </div>
                      {product.picture && <div>
                        <img fetchpriority="low" className='img-product-container' alt='' src={product.picture} />
                      </div>}
                    </div>}
                  </Fragment>

                )
              })}



            </div>


          ))}
        </div>}
        {listProducts && <div className='review-wrapper'>

          {currentRestaurant?.logo &&
            <div className='bottom-logo-container'>
              <img className='bottom-logo' src={currentRestaurant.logo} alt="Logo" />
            </div>}


          <p className='review-title'>{language ? trans[0][language] : "Laisser un avis"}</p>
          <p className='review-text'>{language ? trans[1][language] : 'faites nous part de votre expérience'}</p>
     {currentRestaurant?.review && currentRestaurant?.review === "demo" ?
     <p className='review-icons' onClick={() => alert("Indisponible en mode démo") }>
             <StarOutlined />
            <StarOutlined />
            <StarOutlined />
            <StarOutlined />
            <StarOutlined />
     </p> :
      <a href={currentRestaurant?.review} target="_blank" rel='noreferrer' className='review-icons'>
            <StarOutlined />
            <StarOutlined />
            <StarOutlined />
            <StarOutlined />
            <StarOutlined />
          </a>}
        </div>}
      </div>
    </div>)
}

export default Menu


///<button className='login-button' onClick={signup}>
  //// si option ajouter +3 exemple 3 nbre opt
  ////<img alt='/'  style={{width:20 , height:20, borderRadius:55}} src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2008px-Google_%22G%22_Logo.svg.png'></img>
////<p>Sign in with Google</p> 
////<div style={{width:20 , height:20, borderRadius:55}} ></div>
///</button>